body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 32px;
  margin-left: 10px;
}

html, body {
  height:100%
}

div#root {
  padding-left: 10px;
  padding-right: 10px;
  height:100%;
  background-image: url("./background.jpg");
  background-repeat: repeat-x;
  overflow-y:scroll;
  padding-bottom:20px;
  color:#EEE;
}

nav.navbar {
  ul.navbar-nav {
    li.nav-item, li.nav-item.active {
      color:white;
    }
  
    li.nav-item > a {
      color: white;
    }
  
    button.rs-btn, button.rs-btn:hover, button.rs-btn:focus {
      background-color: transparent;
      color:white;
    }

    button.rs-btn:focus {
      outline-style:none;
    }
  
  }

  img.vglLogo {
    height:30px;
    border:1 px solid grey;
    border-radius: 3px;
  }
}

div.contactCards {
  display:flex;
  padding-top: 20px;
  align-content:center;
  justify-content:center;
  div.contactCard {
    cursor: pointer;
    display: block;
    width: 128px;
    height: 128px;
    border: 1px solid #888;
    border-radius: 3px;
    margin-left: 10px;
    background-color: #343a40;
    opacity:0.75;
    color:white;
    svg {
      height:64px;
      fill: white;
    }
    div.img {
      padding-top: 20px;
    }
  }
  div.contactCard:hover {
    opacity:1;
  };
}

h2.title {
  text-align: center;
}

p.content {
  background-color: #777777;
  opacity:0.75;
  font-size:20px;
  color:white;
  padding: 8px;
  border: 1px solid #999;
  border-radius:3px;
  width:70%;
  margin: 0 auto;
  margin-top:10px;

  img {
    width:100%;
  }

  img.link {
    cursor: pointer;
  }

  video {
    width: 100%;
    max-height:500px;
  }
}

@media only screen and (max-width: 500px) {
  p.content {
    width:99%;
  }
}

div.carousel-root {
  z-index:1;
}

p.content a {
  color:white;
    text-decoration:underline;
    font-weight:bold;
}
p.content.option {
  background-color: #888;
  opacity:0.95
}

p.content.more {
  max-width: 458px;
}

div.product-video {
  margin: 20px auto;
  &.finish {
    width: 100%;
    max-width: 282px;
  }
  &.covertec,
  &.dring,
  &.sizing {
    width: 100%;
    max-width: 400px;
  }
}

div.picture-wrapper {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  .capstrap {
    max-height:350px;
    max-width: 400px;
  }
}